import React from "react";
import { graphql } from "gatsby";
import Layout from "./Layout";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import yellowVestBetweenTwoTrucks from "../images/yellow-vest-between-two-trucks.jpg";
import talkingInsideAWarehouse from "../images/talking-inside-a-warehouse.jpg";

import SEO from "./SEO";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import stylesheet from "./BusinessCaseLayout.module.css";

const Overview = ({ frontmatter, children }) => {
  return (
    <aside className={`${stylesheet.overview ?? ""} fl-blue-context`}>
      {frontmatter.profilePicture?.length
        ? frontmatter.profilePicture.map(picture => (
            <img
              className={stylesheet.profilePicture}
              src={picture.publicURL}
              key={picture.publicURL}
              alt=""
            />
          ))
        : undefined}

      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="business-cases.overview" />
      </div>
      {children}
    </aside>
  );
};

const OverviewWithFrontmatter = frontmatter => {
  return props => Overview({ frontmatter, ...props });
};

const Abstract = ({ children }) => {
  return <div className={stylesheet.abstract}>{children}</div>;
};

const WhyFretlink = ({ children }) => {
  return (
    <div className={`${stylesheet.whyFretlink} ${stylesheet.gridBreaker} fl-grid`}>
      <div className="fl-both-half">{children}</div>
    </div>
  );
};

const Team = ({ children }) => {
  return (
    <aside className={`${stylesheet.teamMemberList ?? ""} fl-blue-context`}>
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="business-cases.fretlink-team" />
      </div>
      {children}
    </aside>
  );
};

const TeamMember = ({ name, position, picture }) => {
  return (
    <div className={stylesheet.teamMember}>
      <img className={stylesheet.teamMemberPicture} src={picture} loading="lazy" alt="" />
      <div className={stylesheet.teamMemberName}>{name}</div>
      <div className={stylesheet.teamMemberPosition}>{position}</div>
    </div>
  );
};

const Feature = ({ children, tag, background }) => {
  return (
    <div className={`${stylesheet.feature} ${stylesheet.gridBreaker} fl-grid`}>
      <div className="fl-first-third fl-blue-context fl-vertical-center">
        <div className="fl-tag fl-naked-tag">{tag}</div>
        {children}
      </div>
      <div className="fl-last-two-thirds">
        <img src={background} alt="" />
      </div>
    </div>
  );
};

const KeyDates = ({ frontmatter, content }) => {
  return (
    <div className={`${stylesheet.keyDates} ${stylesheet.gridBreaker} fl-grid`}>
      <div className="fl-first-third fl-blue-context fl-vertical-center">
        <h3>
          <Trans i18nKey="business-cases.key-dates.title" />
        </h3>
        <p>
          <Trans
            i18nKey="business-cases.key-dates.partnership"
            values={{ companyName: frontmatter.companyName }}
          />
        </p>
      </div>
      <div className={`fl-last-two-thirds ${stylesheet.timelineWrapper}`}>
        <div className={stylesheet.timeline}>
          {content?.map(({ date, text }, index) => (
            <>
              <div className={stylesheet.tlDate}>{date}</div>
              <div className={`${stylesheet.tlBar} ${index === 0 ? stylesheet.tlBarFirst : ""}`} />
              <div className={stylesheet.tlDescription}>{text}</div>
            </>
          ))}
          <div className={`${stylesheet.tlBar} ${stylesheet.tlBarLast}`} />
          <div className={stylesheet.tlDescription}>
            <Trans i18nKey="business-cases.key-dates.today" />
          </div>
        </div>
      </div>
    </div>
  );
};

const KeyDatesWithFrontmatter = frontmatter => {
  return props => KeyDates({ frontmatter, ...props });
};

const CTACarrier = ({ contactName }) => (
  <div className="fl-pan fl-medium-pan fl-pan--with-left-img fl-grid">
    <img className="fl-pan-bg" src={yellowVestBetweenTwoTrucks} loading="lazy" alt="" />
    <div className="fl-second-half fl-vertical-center fl-medium-block">
      <h3>
        <Trans i18nKey="business-cases.cta.carriers.title" />
      </h3>
      <p className="fl-strong">
        <Trans i18nKey="business-cases.cta.carriers.catch-phrase" values={{ contactName }} />
      </p>
      <p>
        <Trans i18nKey="business-cases.cta.carriers.description" />
      </p>
      <p className="fl-link-next">
        <Link to="/sign-up/carrier/">
          <Trans i18nKey="business-cases.cta.request-a-callback" />
        </Link>
      </p>
    </div>
  </div>
);

const CTAShipper = ({ contactName }) => (
  <div className="fl-pan fl-medium-pan fl-pan--with-left-img fl-grid">
    <img className="fl-pan-bg" src={talkingInsideAWarehouse} loading="lazy" alt="" />
    <div className="fl-second-half fl-vertical-center fl-medium-block">
      <h3>
        <Trans i18nKey="business-cases.cta.carriers.title" />
      </h3>
      <p className="fl-strong">
        <Trans i18nKey="business-cases.cta.shippers.catch-phrase" values={{ contactName }} />
      </p>
      <p>
        <Trans i18nKey="business-cases.cta.shippers.description" />
      </p>
      <p className="fl-link-next">
        <Link to="/sign-up/shipper/">
          <Trans i18nKey="business-cases.cta.request-a-callback" />
        </Link>
      </p>
    </div>
  </div>
);

export default ({ data }) => {
  const { t } = useTranslation();

  const shortcodes = {
    Link,
    Overview: OverviewWithFrontmatter(data.mdx.frontmatter),
    Abstract,
    WhyFretlink,
    Team,
    TeamMember,
    Feature,
    KeyDates: KeyDatesWithFrontmatter(data.mdx.frontmatter),
  };

  return (
    <Layout>
      <SEO title={t("business-cases.html-title")} />
      <div className={`fl-grid fl-dot-square-pattern ${stylesheet.businessCaseHeader}`}>
        <div className="fl-top-block fl-first-third fl-green-context fl-vertical-center">
          <div className="fl-tag">
            <Trans i18nKey="business-cases.tag" />
          </div>
          <h1>{data.mdx.frontmatter.companyName}</h1>
          <p className={stylesheet.catchPhrase}>{data.mdx.frontmatter.catchPhrase}</p>
        </div>
        <img
          className={stylesheet.videoThumbnail}
          src={data.mdx.frontmatter.videoThumbnail.publicURL}
          alt=""
        />
      </div>
      <div className={`fl-grid ${stylesheet.businessCaseWrapper}`}>
        <div className={stylesheet.businessCase}>
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
      <div className="fl-lighter-blue-wrapper">
        <div className={`fl-grid ${stylesheet.ctaWrapper}`}>
          {data.mdx.frontmatter.caseType === "shipper" ? (
            <CTAShipper contactName={data.mdx.frontmatter.contactName} />
          ) : (
            <CTACarrier contactName={data.mdx.frontmatter.contactName} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($mdxFileAbsolutePath: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    mdx(fileAbsolutePath: { eq: $mdxFileAbsolutePath }) {
      frontmatter {
        caseType
        companyName
        catchPhrase
        contactName
        profilePicture {
          publicURL
        }
        videoThumbnail {
          publicURL
        }
      }
      body
    }
  }
`;
